<template>
    <b-container>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </b-card>
            </template>
            <b-jumbotron>
                <h4>
                    <router-link :to="{ name: 'notifications' }" class="mr-4">
                        <b-icon-arrow-left-circle scale="1.5"></b-icon-arrow-left-circle>
                    </router-link>
                    <span v-if="id">Edit Notification</span>
                    <span v-else>Add Notification</span>
                </h4>
                <b-form class="mt-4">
                    <b-form-group
                        label="Name"
                    >
                        <b-input v-model="classModel.class_name" />
                    </b-form-group>
                    <b-form-group
                        label="Teacher Names"
                    >
                        <b-input v-model="classModel.teacher_name" />
                    </b-form-group>
                    <b-form-group
                        label="Description"
                    >
                        <b-textarea v-model="classModel.description"></b-textarea>
                    </b-form-group>
                    <b-form-group
                        label="Location"
                    >
                        <b-input v-model="classModel.class_location"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Start Time"
                    >
                        <b-input type="time" v-model="classModel.start_time"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="End Time"
                    >
                        <b-input type="time" v-model="classModel.end_time"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Min Students"
                    >
                        <b-input type="number" v-model.number="classModel.min_students"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Max Students"
                    >
                        <b-input type="number" v-model.number="classModel.max_students"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Age Min"
                    >
                        <b-input type="number" v-model.number="classModel.age_min"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Age Max"
                    >
                        <b-input type="number" v-model.number="classModel.age_max"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Cost"
                    >
                        <b-input-group>
                            <b-input-group-prepend>
                                <b-input-group-text>$</b-input-group-text>
                            </b-input-group-prepend>
                            <b-input type="number" v-model.number="classModel.cost"></b-input>
                            <b-input-group-append>
                                <b-input-group-text>.00</b-input-group-text>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <div class="text-center mt-4">
                        <b-button
                            :disabled="saving"
                            variant="primary"
                            size="lg"
                            @click="saveClass()"
                            pill
                        >
                            <b-spinner v-if="saving" variant="light"></b-spinner>
                            <font-awesome-icon v-else icon="save"></font-awesome-icon>
                            Save Class
                        </b-button>
                    </div>
                </b-form>
            </b-jumbotron>
        </b-skeleton-wrapper>
    </b-container>
</template>
<script>
import { mapState } from 'vuex';
/*import SelectRelationship from '@/components/SelectRelationship.vue'
    components: { SelectRelationship }, */

export default {
    props: ["id"],
    data: function () {
        return {
            loading    : false,
            saving     : false,
            model      : {}
        };
    },
    mounted() {
        if (this.id) this.loadRecord();
    },
    methods: {
        loadRecord() {
            this.loading = true;
            this.$api.get(`/api/notifications/admin/fetch/${this.id}`).then(({ data }) => {
                this.model   = data.record;
                this.loading = false;
            });
        },
        saveRecord() {
            this.saving = true;
            this.classModel.semester_id = this.semester.id;
            this.$api.post(`/api/admin/save-class`, this.classModel).then(({ data }) => {
                if (data.class_)
                {
                    this.classModel = data.class_;
                    if (!this.id)
                        this.$router.push({ name: "edit_class", params: { id: data.class_.id }});
                }
                this.$bvToast.toast(data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                this.saving = false;
            });
        }
    },
    computed: mapState(["semester"])
}
</script>